<template>
    <div class="pvhFactory">
        <basic-container>
            <!--data 数据源 v-model：对象行 -->
            <avue-crud :data="data" ref="crud" v-model="obj" :option="setData.option" :page.sync="page"  @current-change="currentChange"
                       @refresh-change="refreshChange">
              <template slot="menuLeft" style="width: calc(100% - 80px);">
                  <el-row  :gutter="20" >
                    <el-col :xs="24" :sm="16" :md="16" :lg="19" :xl="20" class="fromOutDiv">
                      <el-input v-model.trim="standardCode" placeholder="请输入标准编号" clearable size="small" style="width:200px !important;marginRight: 5px;"></el-input>
                      <el-input v-model.trim="parameterCode" placeholder="请输入参数编号" clearable size="small" style="width:200px !important;marginRight: 5px;"></el-input>
                    </el-col>
                    <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="4" style=" text-align: right; align-self: flex-end;">
                      <el-button type="primary" icon="el-icon-plus" size="mini" :title="$t('New')" plain @click="addInfo"> {{$t('New')}}</el-button>
                      <el-button  :title="$t('Refresh')" type="primary" icon="el-icon-search" size="mini" plain @click="refreshChange">  {{$t('Refresh')}}</el-button>
                    </el-col>
                  </el-row>
                </template>
              <template slot-scope="{row,index,size,type}" slot="menu">
                <el-button icon="el-icon-edit-outline" type="text" size="medium" :title="$t('Edit')" @click="updateInfo(row,index)"></el-button>
              </template>
            </avue-crud>
        </basic-container>
    </div>
</template>
<script>
import {list} from "@/api/parameterStandard";
export default {
        data() {
            return {
              standardCode:"",
              parameterCode:"",
              specificStandards: false,
              enumerationParentKey: false,
              enumerationItemKey: false,
              maxValue: false,
              minValue: false,
              obj:{},
              page: {
                currentPage: 1,
                total: 0,
                layout: "total,pager,prev, next",
                background:false,
                pageSize: 10
              },
              data: [],
            }
        },
        computed: {
            setData() {
                return {
                    option: {
                      page: true,
                      emptyText: this.$t('No Data'),
                      menuTitle: this.$t('OPERATION'),
                      // menu:false,
                      menuWidth: 140,
                      align: 'center',
                      columnBtn: false,
                      simplePage: false,
                      editTitle: this.$t('Edit'),
                      addTitle: this.$t('Add'),
                      editBtn:false,
                      delBtn:false,
                      labelPosition: 'top',
                      menuAlign: 'center',
                      addBtn: false,
                      refreshBtn: false,
                      column: [
                            {
                                label: '标准编号',
                                prop: 'standardCode'
                            },
                            {
                                label: '标准名称',
                                prop: 'standardName'
                            },
                        ]
                    }
                }
            }
        },
        created() {
            this.getList()
        },
        methods: {
          refreshChange() {
            this.getList()
          },
          addInfo() {
            this.$router.push({ path: '/quality/parameterStandardAdd', query: { sign: 'add', ID: '' } })
          },
          //修改
          updateInfo(row) {
            this.$router.push({ path: '/quality/parameterStandardAdd', query: { sign: 'update', standardCode: row.standardCode } })
          },
          getList() {
            const xx={
              pageNo: this.page.currentPage,
              pageSize: this.page.pageSize,
              total: this.page.total,
              parameterCode:this.parameterCode,
              standardCode:this.standardCode,
            };
            list(xx).then(res => {
              if (res.data.data == null){
                this.data = [];
                this.page.total = 0;
              } else {
                this.page.total = res.data.data.total
                this.data = res.data.data.items
              }
              console.log(res,'res')
            }).catch(erro => {
              console.log(erro);
            })
          },
          currentChange(val) {
            this.page.currentPage = val
            this.getList()
          }
        },
        watch:{
          'obj.specificStandards'(New) {
            if (New != '') {
              this.enumerationParentKey = true;
              this.enumerationItemKey = true;
              this.maxValue = true;
              this.minValue = true;
            } else {
             this.enumerationParentKey = false;
              this.enumerationItemKey = false;
              this.maxValue = false;
              this.minValue = false
            }
         },
          'obj.enumerationParentKey'(New) {
            if (New != '') {
              this.specificStandards = true;
              this.minValue = true;
              this.maxValue = true;
            } else {
              this.specificStandards = false;
              this.minValue = false;
              this.maxValue = false;
            }
          },
          'obj.enumerationItemKey'(New) {
            if (New != '') {
              this.specificStandards = true;
              this.minValue = true;
              this.maxValue = true;
            } else {
              this.specificStandards = false;
              this.minValue = false;
              this.maxValue = false;
            }
          },
          'obj.minValue'(New) {
            if (New != '') {
              this.specificStandards = true;
              this.enumerationParentKey = true;
              this.enumerationItemKey = true;
            } else {
              this.specificStandards = false;
              this.enumerationParentKey = false;
              this.enumerationItemKey = false;
           }
         },
          'obj.maxValue'(New) {
            if (New != '') {
              this.specificStandards = true;
              this.enumerationParentKey = true;
              this.enumerationItemKey = true;
            } else {
              this.specificStandards = false;
              this.enumerationParentKey = false;
              this.enumerationItemKey = false;
            }
          }
        }
      }
</script>
<style scoped>
</style>
